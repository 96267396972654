<template>
    <div class="action-gift">
        <div class="timestamp flex-wrap">{{ message.created_at | formatDate('A h:mm') }}</div>
        <div class="gift-message">
            <div class="gift-wrapper">
                <img :src="contentImageUrl" />
                <div class="gift-description-name">
                    <div class="gift-inner-wrapper">
                        <div class="gift-description">{{ contentDescription }}</div>
                        <div class="gift-name">{{ contentName }}</div>
                    </div>
                </div>
            </div>
            <div class="sent-message">
                <div>{{ contentAgentName }}({{ contentAgentNickname }})님에게</div>
                <div>감사의 선물을 보냈어요🎁</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionGiftInfo',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        contentDescription() {
            return this.content.description
        },
        contentName() {
            return this.content.name
        },
        contentAgentName() {
            return this.content.agent.name
        },
        contentAgentNickname() {
            return this.content.agent.nickname
        },
        contentImageUrl() {
            return this.content.photo_url
        },
    },
}
</script>

<style lang="scss" scoped>
.action-gift {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .gift-message {
        display: flex;
        flex-direction: column;

        width: 200px;
        height: 271px;
        border-radius: 12px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05), 0 1px 4px 0 rgba(84, 72, 203, 0.05);
        background-color: #ffffff;

        .gift-wrapper {
            position: relative;

            img {
                width: 200px;
                height: 200px;
            }

            .gift-description-name {
                position: absolute;
                top: 100px;
                width: 200px;
                height: 100px;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));

                .gift-inner-wrapper {
                    position: absolute;
                    width: 188px;
                    height: 88px;
                    left: 12px;
                    bottom: 12px;
                    padding-right: 8px;

                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    .gift-description {
                        font-family: NotoSansCJKkr;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #ffffff;
                        margin-bottom: 2px;
                    }

                    .gift-name {
                        font-family: NotoSansCJKkr;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.27;
                        letter-spacing: normal;
                        color: #ffffff;
                    }
                }
            }
        }

        .sent-message {
            padding: 12px;

            font-family: NotoSans-Regular;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.2px;
            color: #000000;
        }
    }
}
</style>
